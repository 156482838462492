<template>
  <div class="land-container">
    <div class="video">
      <video
        src="http://res.quanbangcloud.com/videos/00/00123b80abe14e3faa1f343606526175.mp4"
        poster="@/assets/images/video_cover.png"
        loop="loop"
        ref="video"
        :controls="controls"
        @click="handlePlay(false)"
      ></video>
    </div>
    <div class="play_btn" @click="handlePlay(true)" v-if="showPlayBtn">
      <img src="@/assets/images/fission/play_btn.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LandVideo',
  data() {
    return {
      showPlayBtn: true,
      controls: false
    }
  },
  methods: {
    handlePlay(flag) {
      if (flag) {
        this.$refs.video.play()
        this.controls = true
        this.showPlayBtn = false
        return
      }
      this.$refs.video.pause()
      this.showPlayBtn = true
      this.controls = false
    }
  }
}
</script>

<style lang="less" scoped>
.land-container {
  padding-top: 84px;
  padding-bottom: 58px;
  position: relative;
  .video {
    width: 335px;
    height: 182px;
    margin: 0 auto;
    video {
      object-fit: cover;
      width: 100%;
    }
  }
  .play_btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%, -50%);
    width: 26px;
    height: 26px;
  }
}
</style>
