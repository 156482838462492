<template>
  <div class="conclusion-container">
    赋予别人赚钱的能力是企<br />
    业家最大的资本。
  </div>
</template>

<script>
export default {
  name: 'LandConclusion'
}
</script>

<style lang="less" scoped>
.conclusion-container {
  width: 375px;
  height: 109px;
  background: #1651dd;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
  line-height: 28px;
  padding: 27px 0;
  margin-top: 27px;
}
</style>
