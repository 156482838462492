<template>
  <div class="land-content-container">
    <h3 class="title">裂变落地系统</h3>
    <div class="under-line"></div>
    <div class="introdution">
      「裂变落地系统」就是让企业原有的产品和项目更好地适应当前的市场环境的全新商业模式和系统。通过转变企业经营者经营思维，改变经营方式，以实现裂变，从而创造更多盈利，最终实现企业的人才裂变，渠道裂变，时间裂变
    </div>
    <ul>
      <li v-for="item in List" :key="item.id">
        <h3 class="title1">{{ item.title }}</h3>
        <div class="introdution1">{{ item.introdution }}</div>
      </li>
    </ul>
    <div class="programs"></div>
  </div>
</template>

<script>
export default {
  name: 'LandContent',
  data() {
    return {
      List: [
        {
          id: 1,
          title: '商业模式设计',
          introdution:
            '我们将为您带来设计模式的核心：把有形资产打包到无形产品中去；让生意更简单，让钱更好赚的模式就是好模式。'
        },
        {
          id: 2,
          title: '融合社会资源',
          introdution:
            '我们将为您带来5种员工入股方案，7种高管入股及激励方案。现场板书案例，教学员做方案的逻辑，算法及步骤。'
        },
        {
          id: 3,
          title: '招募加盟商设计',
          introdution:
            '我们将为您分享加盟的方法，加盟收益的组成部分，招商路演的全套流程，甚至把公司各式会议的流程模版都设计好共享到学员群里'
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.land-content-container {
  padding: 24px 20px 0;
  .title {
    font-size: 24px;
    font-weight: 500;
    color: #010343;
    line-height: 33px;
    text-align: center;
  }
  .under-line {
    width: 206px;
    border-bottom: 2px solid #010343;
    margin: 0 auto;
  }
  .introdution {
    width: 334px;
    height: 130px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #010343;
    line-height: 26px;
    text-align: center;
    margin-bottom: 17px;
  }
  li {
    width: 335px;
    height: 99px;
    margin-bottom: 29px;
  }
  .title1 {
    width: 335px;
    height: 32px;
    background: #1651dd;
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
    line-height: 33px;
    text-align: center;
  }
  .introdution1 {
    width: 335px;
    height: 67px;
    background: #010343;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
    padding: 3px 12px;
    text-align: center;
    line-height: 20px;
  }
  .programs {
    width: 335px;
    height: 150px;
    background: url(~@/assets/images/land/land-programs.png);
    background-size: cover;
    margin-top: 48px;
  }
}
</style>
