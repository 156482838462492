<template>
  <div class="land-container">
    <!-- navbar -->
    <QbNavbar title="裂变落地系统"></QbNavbar>
    <!-- title -->
    <QbTitle
      title="裂变落地系统"
      subtitle="Leading the change of business model"
    ></QbTitle>
    <!-- LandVideo -->
    <LandVideo></LandVideo>
    <!-- Land-Lecturer -->
    <LandLecturer></LandLecturer>
    <!-- land system -->
    <LandSystem></LandSystem>
    <!-- land-content -->
    <LandContent></LandContent>
    <!-- land-conslusion -->
    <LandConslusion></LandConslusion>
    <!-- qb-application -->
    <qb-application></qb-application>
  </div>
</template>

<script>
import LandVideo from './components/land-video.vue'
import LandLecturer from './components/land-lecturer.vue'
import LandSystem from './components/land-system.vue'
import LandContent from './components/land-content.vue'
import LandConslusion from './components/land-conslusion.vue'
export default {
  name: 'Land',
  data() {
    return {}
  },
  components: {
    LandVideo,
    LandLecturer,
    LandSystem,
    LandContent,
    LandConslusion
  }
}
</script>

<style lang="less" scoped></style>
