<template>
  <div class="land-system-container">
    <h3 class="title">课程体系</h3>
    <div class="under-line"></div>
    <div class="introdution">
      「裂变落地系统」课程，推动企业管理进入新时代，是思维和经营模式的革命，泉邦集团作为主办方，凝聚实践智慧，以累积数载经营哲学和方法，为经营者传递信心与力量，帮助企业抢占模式变革的制高点，携手TA们一道做管理变革下的逐梦者。
    </div>
    <ul class="content">
      <li v-for="item in systemList" :key="item.id">
        <dl>
          <dt>
            <h3 class="title-2">{{ item.title }}</h3>
            <h3 class="subtitle-2">{{ item.subTitle }}</h3>
          </dt>
          <dd class="mb mr">
            {{ item.item1 }}
          </dd>
          <dd>
            {{ item.item2 }}
          </dd>
          <dd class="mb mr">
            {{ item.item3 }}
          </dd>
          <dd>
            {{ item.item4 }}
          </dd>
          <dd class="mr">
            {{ item.item5 }}
          </dd>
          <dd>
            {{ item.item6 }}
          </dd>
        </dl>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'LandSystem',
  data() {
    return {
      systemList: [
        {
          id: 1,
          title: '融资体系',
          subTitle: '学会融资规划，让人才，资金主动找上门来',
          item1: '消费型股东招募',
          item2: '企业超级拆分',
          item3: '资源型股东招募',
          item4: '企业顶层布局',
          item5: '投资型股东招募',
          item6: '企业内部融资'
        },
        {
          id: 2,
          title: '招商体系',
          subTitle: '掌握招商部剧，构建销售网络，达到全国裂变',
          item1: '服务流程体系',
          item2: '渠道层级分配',
          item3: '会销成交设计',
          item4: '城市布局体系',
          item5: '会务布局体系',
          item6: '顾客裂变体系'
        },
        {
          id: 3,
          title: '会议系统',
          subTitle: '植入会议体系，让企业内部自动自发，良性运转',
          item1: '服务流程体系',
          item2: '渠道层级分配',
          item3: '会销成交设计',
          item4: '城市布局体系',
          item5: '会务布局体系',
          item6: '顾客裂变体系'
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.land-system-container {
  padding: 47px 20px 0;
  .title {
    font-size: 24px;
    font-weight: 500;
    color: #010343;
    line-height: 33px;
    text-align: center;
  }
  .under-line {
    width: 206px;
    border-bottom: 2px solid #010343;
    margin: 0 auto;
  }
  .introdution {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #010343;
    line-height: 22px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  li {
    margin-bottom: 23px;
  }
  dl {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  dt {
    width: 335px;
    height: 69px;
    height: 69px;
    background: #1651dd;
    padding-top: 10px;
    margin-bottom: 28px;
    .title-2 {
      font-size: 24px;
      font-weight: 500;
      color: #ffffff;
      line-height: 33px;
      text-align: center;
    }
    .subtitle-2 {
      font-size: 14px;
      font-weight: 300;
      color: #ffffff;
      line-height: 20px;
      text-align: center;
      opacity: 0.8;
    }
  }
  dd {
    width: 153px;
    height: 49px;
    background: #f7f9fd;
    font-size: 16px;
    font-weight: 400;
    color: #010343;
    line-height: 49px;
    text-align: center;
    &.mb {
      margin-bottom: 28px;
    }
    &.mr {
      margin-right: 29px;
    }
  }
}
</style>
