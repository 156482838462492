<template>
  <div class="lecturer-container">
    <h3 class="title">裂变落地系统讲师</h3>

    <swiper class="swiper" :options="swiperOption">
      <swiper-slide
        class="swiper-item"
        v-for="item in landLecturerList"
        :key="item.id"
      >
        <img :src="item.picture" alt="" />
      </swiper-slide>
    </swiper>

    <QbMore top="45" @click.native="$router.push('/lecturer')"></QbMore>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import QbMore from '@/components/qb-more.vue'
export default {
  name: 'Lecturer',
  components: {
    swiper,
    swiperSlide,
    QbMore
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 20,
        loop: true,
        centeredSlides: true,
        autoplay: {
          delay: 2000
        }
      },
      landLecturerList: [
        {
          id: 1,
          picture: require('@/assets/images/lecturer/mentor_1.png')
        },
        {
          id: 2,
          picture: require('@/assets/images/lecturer/mentor_2.png')
        },
        {
          id: 3,
          picture: require('@/assets/images/lecturer/mentor_3.png')
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.lecturer-container {
  padding-top: 62px;
  padding-bottom: 31px;
  background: #f7f9fd;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.08);
  .title {
    font-size: 21px;
    font-weight: 700;
    line-height: 29px;
    text-align: center;
    margin-bottom: 39px;
  }
  .swiper-item {
    width: 260px;
    height: 412px;
    background: #00123f;
  }
}
</style>
